.componentBase {
  color: currentColor;
  text-decoration: none;
}

.componentText {
  text-decoration: underline;
  font-size: var(--font-size-16-20);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.componentBlock {
  display: block;
}
