.component {
  & > .fullHeightContainer {
    min-height: 100vh;

    /* Specifically target Mobile Safari */
    @supports (-webkit-touch-callout: none) {
      min-height: -webkit-fill-available;
    }
  }
}

.fullHeightContainer {
  display: grid;
  grid-template-rows: auto 1fr;
}

.componentIntroduction {
  display: grid;
  align-content: flex-end;
  grid-template-rows: repeat(3, min-content);
  color: var(--color-white);
  gap: var(--size-30);

  @media (--viewport-md) {
    gap: var(--size-60);
  }

  padding-top: var(--size-30);
  padding-bottom: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-100);
    padding-bottom: var(--size-100);
  }

  & > * {
    max-width: max(50vw, 768px);
    min-width: 0;
  }
}
