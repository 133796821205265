.componentBase {
  line-height: var(--line-height-base);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.componentSm {
  font-size: var(--font-size-16-20);
  line-height: var(--line-height-base);
}

.componentMd {
  text-transform: uppercase;
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-16-20);
  letter-spacing: var(--letter-spacing-sm);
}

.componentLg {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-18-30);
  letter-spacing: var(--letter-spacing-md);
}

.componentXl {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-24-40);
  letter-spacing: var(--letter-spacing-md);
}

.componentXxl {
  font-weight: var(--font-weight-md);
  font-size: var(--font-size-30-56);
  letter-spacing: var(--letter-spacing-md);
}
