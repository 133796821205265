.component {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-10);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, min-content);
    gap: var(--size-30);
  }

  padding-top: var(--size-30);
  padding-bottom: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-60);
    padding-bottom: var(--size-60);
  }
}

.animatedLogoContainer {
  & > * {
    height: var(--size-30);
  }
}
