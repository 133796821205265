.component {
  display: grid;
  gap: var(--size-100);
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--size-20);

  @media (--viewport-md) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.componentMoreQuestions {
  display: grid;

  & > .moreQuestionsParagraph {
    grid-column: span 2;
  }
}

.componentColumn {
  display: grid;
  grid-template-rows: min-content;
}

.componentLogoContainer {
  gap: var(--size-10);

  & > * {
    height: var(--size-20);
  }
}
