.componentError {
  display: grid;
  align-content: end;
  color: var(--color-white);
  padding-top: var(--size-30);
  padding-bottom: var(--size-30);
  gap: var(--size-10);

  @media (--viewport-md) {
    padding-top: var(--size-100);
    padding-bottom: var(--size-100);
  }
}

.componentPositioning {
  & > * {
    height: 100vh;
  }
}

.componentContent {
  display: grid;
  grid-template-rows: auto 1fr;
}
