.component {
  display: flex;

  & > :not(:last-child) {
    margin-right: var(--size-10);
  }

  & > .iconLayout {
    margin-top: 5px;
  }
}
