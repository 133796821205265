.component {
  padding-top: var(--size-60);
  padding-bottom: var(--size-60);
}

.category {
  padding: var(--size-20) 0 !important;
}

.questionContainer {
  display: grid;
  gap: 1rem;
}

.container {
  & > .question {
    width: 100%;
  }
}
