.component {
  & > * {
    width: 100%;
  }
}

.inner {
  & > * {
    /* Required to target dangerouslySetInnerHTML's children */
    /* stylelint-disable-next-line kaliber/layout-related-properties */
    color: var(--color-gray-900);
  }
}

.componentQuestion {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--size-20);
  border-bottom: var(--line-thickness-base) solid var(--color-gray-900);
  text-align: left;

  & > *:first-child {
    flex: 1;
  }

  & > *:last-child {
    flex: 0 1 auto;
  }
}

.componentAnswer {
  overflow: hidden;
}

.innerQuestionPadding,
.innerAnswerPadding {
  padding: var(--size-20) 0 !important;
}
