.componentBase {
  display: inline-flex;
  vertical-align: top;
  justify-content: center;
  line-height: var(--line-height-base);
  border: var(--line-thickness-base) solid var(--color-white);
  padding: var(--size-10) var(--size-20);
  transition: var(--default-transition);

  &:focus,
  &:hover {
    border-color: var(--hover-color);
  }
}
