:root {
  --color-white: #fff;
  --color-black: #000;
  --color-gray-900: #262626;
  --color-purple-500: #7b1be9;
  --color-blue-500: #08489a;
  --color-red-500: #f40a1e;

  --hover-color: #999;
}
