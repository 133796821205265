.componentImageSection {
  display: flex;

  & > * {
    width: 100%;
    height: 300px;

    @media (--viewport-md) {
      max-height: 800px;
      height: 100%;
    }
  }
}

.image {
  object-fit: cover;
}

.introduction {
  padding-top: var(--size-100);
  padding-bottom: var(--size-100);
}

.faq {
  padding-top: var(--size-60);
}

.faq,
.section,
.footer {
  position: relative;
}

.componentFooterSection {
  padding-top: var(--size-60);
  padding-bottom: var(--size-30);

  @media (--viewport-md) {
    padding-top: var(--size-120);
    padding-bottom: var(--size-60);
  }
}
