:root {
  --font-family-base: Helvetica, Arial, -apple-system, sans-serif;

  --font-weight-lg: 600;
  --font-weight-md: 500;
  --font-weight-base: 400;

  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1024;

  --font-size-unit: 1px;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --line-height-base: 1.2;
  --line-height-text: 1.35;

  --font-size-30-56: clamp(30px, calc(30px + 26 * var(--m)), 56px);
  --font-size-24-40: clamp(24px, calc(24px + 16 * var(--m)), 40px);
  --font-size-18-30: clamp(20px, calc(20px + 10 * var(--m)), 30px);
  --font-size-16-20: clamp(16px, calc(16px + 4 * var(--m)), 20px);

  --letter-spacing-md: -0.03em;
  --letter-spacing-sm: -0.01em;
}
