.componentBaseCenteredContainer {
  display: flex;
  justify-content: center;
  padding-left: var(--size-30);
  padding-right: var(--size-30);

  & > * {
    width: 100%;

    @media (orientation: landscape) {
      @supports (-webkit-touch-callout: none) {
        margin-left: env(safe-area-inset-left);
        margin-right: env(safe-area-inset-right);
      }
    }
  }

  & > .sm { max-width: var(--container-sm); }
  & > .md { max-width: var(--container-md); }
  & > .lg { max-width: var(--container-lg); }
}
